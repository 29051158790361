@import '@/styles/_shared.scss';

.typography {
  line-height: unset !important;

  &--bebas {
    font-family: $bebas !important;
  }

  &--frank {
    font-family: $frank-ruhl !important;
  }

  &--bold {
    font-weight: bold;
  }

  &--title {
    font-size: 64px;
    @include breakpoint-down('md') {
      font-size: 54px;
    }
  }

  &--title-2 {
    font-size: 60px;
    @include breakpoint-down('md') {
      font-size: 50px;
    }
  }

  &--subtitle {
    font-size: 48px;
    @include breakpoint-down('md') {
      font-size: 36px;
    }
  }

  &--subtitle-2 {
    font-size: 40px;
    @include breakpoint-down('md') {
      font-size: 32px;
    }
  }

  &--heading {
    font-size: 32px;
    @include breakpoint-down('md') {
      font-size: 28px;
    }
  }

  &--subheading {
    font-size: 24px;
    @include breakpoint-down('md') {
      font-size: 20px;
    }
  }

  &--body {
    font-size: 16px;
  }

  &--body-1 {
    font-size: 24px;
    @include breakpoint-down('md') {
      font-size: 16px;
    }
  }

  &--body-2 {
    font-size: 20px;
    @include breakpoint-down('md') {
      font-size: 18px;
    }
  }

  &--body-3 {
    font-size: 18px;
    @include breakpoint-down('md') {
      font-size: 16px;
    }
  }

  &--body-4 {
    font-size: 14px;
  }
}
