@import '@/styles/_shared.scss';

.full-bleed-video-container {
  overflow: hidden;
  position: relative;

  height: 90vh;
  max-height: 50vw;
  @include breakpoint-down('sm') {
    max-height: unset;
  }

  &__video {
    pointer-events: none;
    position: relative;
    z-index: -1;
    transform: scale(1.5);
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(99, 99, 99, 0) 0%,
        rgba(2, 2, 2, 0.6) 45%
      );
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 36px;
  }

  &__buttons-container {
    display: flex;
    gap: 16px;
  }
}
