@import '@/styles/_shared.scss';

.button {
  padding: 14px 30px;
  font-family: $bebas;
  text-align: center;
  border: none;
  text-decoration: none;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  gap: 8px;

  &:hover {
    text-decoration: none !important;
    filter: brightness(80%);
  }

  &--rounded {
    border-radius: 48px;
  }

  &--full-width {
    width: 100%;
  }

  &--frank {
    font-family: $frank-ruhl !important;
  }

  &--text-xs {
    font-size: 14px;
  }

  &--text-lg {
    font-size: 18px;
  }

  &--text-xl {
    font-size: 22px;
  }

  &--bold {
    font-weight: 500;
  }

  &--ghost {
    background: transparent;
    border: 1px solid;
  }

  &--light {
    background: none;
    border: none;
    text-decoration: underline;
    padding: 14px 0px;

    &:hover {
      text-decoration: underline !important;
    }
  }
}
